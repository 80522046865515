import "../styles/index.scss";
import M from "materialize-css/dist/js/materialize.min";

window.document.addEventListener("DOMContentLoaded", function () {
  M.AutoInit();
});

document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".datepicker");
  const options = {
    format: "dd/mm/yyyy",
    yearRange: 100,
  };
  M.Datepicker.init(elems, options);
});

window.document.addEventListener("DOMContentLoaded", function () {
  const dashboardFilters = document.getElementById("dashboard-filters");

  if (!dashboardFilters) {
    return;
  }

  window.startDateField = document.getElementById("id_date_start");
  window.endDateField = document.getElementById("id_date_end");
  window.financialYearField = document.getElementById("id_financial_year");
  window.quarterField = document.getElementById("id_quarter");

  window.startDateFieldContainer = document.getElementById("id_date_start_container");
  window.endDateFieldContainer = document.getElementById("id_date_end_container");
  window.financialYearFieldContainer = document.getElementById("id_financial_year_container");
  window.quarterFieldContainer = document.getElementById("id_quarter_container");

  window.startDateField.addEventListener("change", window.useSearchByManualDates);
  window.endDateField.addEventListener("change", window.useSearchByManualDates);
  window.financialYearField.addEventListener("change", window.useSearchByFinancialYear);
  window.quarterField.addEventListener("change", window.useSearchByQuarter);
});

window.useSearchByFinancialYear = function () {
  deactivateQuarterField();
  deactivateManualDateFields();
};

window.useSearchByQuarter = function () {
  deactivateFinancialYearField();
  deactivateManualDateFields();
};

window.useSearchByManualDates = function () {
  deactivateFinancialYearField();
  deactivateQuarterField();
};

function deactivateQuarterField() {
    window.quarterField.value = "";
    M.FormSelect.init(window.quarterField);
}

function deactivateFinancialYearField() {
    window.financialYearField.value = "";
    M.FormSelect.init(window.financialYearField);
}

function deactivateManualDateFields() {
  window.startDateField.value = "";
  window.endDateField.value = "";
}

window.document.showLoadingSpinner = () => {
  const dashboardContent = document.getElementById("dashboard-content");
  const spinnerContainer = document.getElementById("spinner-container");
  if (dashboardContent !== null) {
    dashboardContent.style.display = "none";
  }
  if (spinnerContainer !== null) {
    spinnerContainer.style.display = "block";
  }
};

window.downloadChart = (id, data) => {
  const cleanData = JSON.parse(data);
  const imageLink = document.createElement("a");
  const canvas = document.getElementById(id);
  console.log(canvas);
  imageLink.download = `${cleanData.title}.png`;
  imageLink.href = canvas.toDataURL("image/png", 1);
  imageLink.click();
};
